import React, { useState } from 'react';
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import FsLightbox from 'fslightbox-react';
import { Carousel } from 'react-bootstrap';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import FloorplanModal from '@components/sticky-nav/floorplan-modal'
import ImageRenderer from "@components/ImageRenderer/ImageRenderer";
import EpcModal from '@components/sticky-nav/epc-modal';
import { Heart, Photos, Video, Brochure } from '@components/icon/icon';

/**
 * Assets
 */

import './styles/_index.scss';


const PropertyGallery = (props) => {

    let processedImages = JSON.stringify({});
    if (props.data?.imagetransforms?.images_Transforms) {
        processedImages = props.data.imagetransforms.images_Transforms;
    }
    const data = useStaticQuery(graphql`
        query {
            propertyImage: file(relativePath: { eq: "property-detail.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage2: file(relativePath: { eq: "property-detail2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage3: file(relativePath: { eq: "property-detail3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const FloorplanImages = props.floorplan && props.floorplan.map(img => img.srcUrl);

    const brochure = props?.data?.brochure && props?.data?.brochure.length > 0  ? props?.data?.brochure : "";
    const epc  =  props?.data?.epc && props?.data?.epc.map(img => img.srcUrl);
    const virtualTour = props?.data?.virtual_tour ? props?.data?.virtual_tour : "";

    //console.log("FloorplanImages", FloorplanImages)
    const images = [data.propertyImage.childImageSharp.fluid, data.propertyImage2.childImageSharp.fluid, data.propertyImage3.childImageSharp.fluid];
    const lightImages = images.map(img => img.src);
    const lightImages2 = props.propertyimages && props.propertyimages.map(img => (img.url) ? img.url : img.srcUrl);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1 
    });

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
        });
    }

    const Slider = () => (
        <Carousel indicators={false}>
            {props.propertyimages.map((item, ind) => (
                
            
                <Carousel.Item key={ind}>
                    <a href="/" onClick={(e) => openLighboxMobile(e, 1)}>
                     <ImageRenderer ImageSrc={item?.propertyimages[ind]} altText={props.address+'- Anthony Pepe'} ggfx_results={props?.ggfx_results} strapi_id={props?.id} imagename="property.images.detail" />
                    </a>
                    <a href="/" className="heart d-md-none d-none">
                        <Heart />
                    </a>
                    <div className="utils">
                        {/*<div className="utils__progress d-none d-md-flex">{ind+1}/{props.propertyimages.length} Photos</div>
                        <button type="button" className="utils__fullscreen d-md-block" onClick={(e) => openLighboxMobile(e, ind+1)}>Fullscreen<Fullscreen /></button>*/}
                        
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    )

    return (
        <div className="property-gallery">
            {lightImages2 && <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightImages2}
                type='image'
                slide={lightboxController.slide}
            />}
              {/*lightImages2 && <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightImages2}
                type='image'
                slide={lightboxController.slide}
            />*/}

             {/*<Slider /> */}
             <div className='container img-container'>
                <Row>
                    <Col md={12}>
                        <div className="property-banner">                
                            <div className="banner-left zoom">
                                <div className="property-img">
                                    <a href="/" onClick={(e) => openLighboxMobile(e, 1)}>
                                            <ImageRenderer ImageSrc={props?.propertyimages[0]} altText={props.address+'- Anthony Pepe'} ggfx_results={props?.ggfx_results} strapi_id={props?.id} imagename="property.images.detail" />
                                    </a>
                                   
                                    <div className="utils">
                                    <div className='container utils-container'>
                                        {props.propertyimages.length>0 &&
                                        <div className="btn-utils-wrap">
                                        <button type="button" className="utils__progress btn-prop-utils" onClick={(e) => openLighboxMobile(e, 1)}><Photos /><span>{props.propertyimages.length}</span></button>
                                        <span className="btn-prop-separator"></span>
                                        </div>
                                        }
                                        {FloorplanImages && 
                                        <div className="btn-utils-wrap">
                                        <button type="button" className="utils__fullscreen btn-prop-utils"><FloorplanModal floorplanimages={FloorplanImages}/></button>
                                        <span className="btn-prop-separator"></span>
                                        </div>
                                        }
                                        {virtualTour && 
                                        <div className="btn-utils-wrap">
                                         <a href={virtualTour} className="nav-link btn-prop-utils virtualTour utils__fullscreen" target="_blank">
                                            <Video /><span>Video</span>
                                         </a>
                                         <span className="btn-prop-separator"></span>
                                         </div>
                                        }
                                        {brochure &&
                                        <div className="btn-utils-wrap">
                                         <a href={brochure} className="nav-link btn-prop-utils utils__fullscreen brochure" target="_blank">
                                            <Brochure /><span>Brochure</span>
                                         </a>
                                         <span className="btn-prop-separator"></span>
                                         </div>
                                        }
                                        
                                        {epc && 
                                        <div className="btn-utils-wrap">
                                          <button type="button" className="utils__fullscreen epc btn-prop-utils"> <EpcModal floorplanimages={epc}/></button>
                                          </div>
                                        }
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className="banner-right d-none d-lg-flex">
                                <div className="banner-right-top zoom">  
                                    <a href="/" onClick={(e) => openLighboxMobile(e, 2)}>                              
                                        <ImageRenderer ImageSrc={props?.propertyimages[1]} altText={props.address+'- Anthony Pepe'} ggfx_results={props?.ggfx_results} strapi_id={props?.id} imagename="property.images.detailThumb" />
                                    </a>
                                </div>
                                <div className="banner-right-bottom zoom">
                                    <a href="/" onClick={(e) => openLighboxMobile(e, 3)}>
                                        <ImageRenderer ImageSrc={props?.propertyimages[2]} altText={props.address+'- Anthony Pepe'} ggfx_results={props?.ggfx_results} strapi_id={props?.id} imagename="property.images.detailThumb" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
           
        </div>
    )
}

export default PropertyGallery;
